import React from "react";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import ViewModule from "@material-ui/icons/ViewModule";
import Star from "@material-ui/icons/Star";
import Home from "@material-ui/icons/Home";
import Description from "@material-ui/icons/Description";
import LiveTv from "@material-ui/icons/LiveTv";
import VideoLabel from "@material-ui/icons/VideoLabel";
import SettingsIcon from "@material-ui/icons/Settings";
import Share from "@material-ui/icons/Share";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CloudUpload from "@material-ui/icons/CloudUpload";
import SentimentVerySatisfied from "@material-ui/icons/SentimentVerySatisfied";
import Snackbar from "@material-ui/core/Snackbar";
import MenuItem from "@material-ui/core/MenuItem";
import LocalOffer from "@material-ui/icons/LocalOffer";
import NoteAdd from "@material-ui/icons/NoteAdd";
import Select from "../../components/Select/Select";

import { connect } from "react-redux";
import * as actions from "../../store/actions";
import Loading from "../../components/Loading/Loading";
import axios from "../../axios";

const defaultSettings = {
  logo: "",
  footer_logo: "",
  favicon: "",
  google_analytics_id: "",
  yandex_metrica_id: "",
  alexa_metrics_id: "",
  hottest_page_title: "",
  hottest_page_description: "",
  hottest_desktop_list_size: "",
  hottest_mobile_list_size: "",
  most_liked_page_title: "",
  most_liked_page_description: "",
  most_liked_desktop_list_size: "",
  most_liked_mobile_list_size: "",
  most_viewed_page_title: "",
  most_viewed_page_description: "",
  most_viewed_desktop_list_size: "",
  most_viewed_mobile_list_size: "",
  tag_desktop_list_size: "",
  tag_mobile_list_size: "",
  search_desktop_list_size: "",
  search_mobile_list_size: "",
  home_seo_title: "",
  home_seo_description: "",
  home_trending_desktop_list_size: "",
  home_trending_mobile_list_size: "",
  home_most_liked_desktop_list_size: "",
  home_most_liked_mobile_list_size: "",
  home_most_liked_view_more_count: "",
  home_hottest_desktop_list_size: "",
  home_hottest_mobile_list_size: "",
  home_hottest_view_more_count: "",
  home_pornstar_desktop_list_size: "",
  home_pornstar_mobile_list_size: "",
  home_channel_desktop_list_size: "",
  home_channel_mobile_list_size: "",
  detail_primary_list_size: "",
  detail_secondary_list_size: "",
  detail_mobile_list_size: "",
  categories_page_title: "",
  categories_page_description: "",
  categories_videos_desktop_list_size: "",
  categories_videos_mobile_list_size: "",
  pornstars_page_title: "",
  pornstars_page_description: "",
  pornstars_desktop_list_size: "",
  pornstars_mobile_list_size: "",
  pornstars_videos_desktop_list_size: "",
  pornstars_videos_mobile_list_size: "",
  channels_page_title: "",
  channels_page_description: "",
  channels_desktop_list_size: "",
  channels_mobile_list_size: "",
  channels_videos_desktop_list_size: "",
  channels_videos_mobile_list_size: "",
  social_media_twitter: "",
  social_media_vk: "",
  social_media_reddit: "",
  social_media_facebook: "",
  social_media_instagram: "",
  footer_description: "",
  general_title: "",
  detail_seo_description_template: "",
  google_site_verification_code: "",
  exoclick_site_verification_code: "",
  clickadu_site_verification_code: "",
  yandex_site_verification_code: "",
  category_seo_description_template: "",
  channel_seo_description_template: "",
  pornstar_seo_description_template: "",
  tag_seo_description_template: "",
  exoclick_desktop_footer_ad_1: "",
  exoclick_desktop_footer_ad_2: "",
  exoclick_desktop_footer_ad_3: "",
  exoclick_desktop_footer_ad_4: "",
  exoclick_mobile_footer_ad: "",
  exoclick_desktop_video_detail_sidebar: "",
  exoclick_desktop_video_detail_under_video: "",
  exoclick_mobile_video_detail_under_video: "",
  trending_random_order: 1,
  exoclick_desktop_player1: "",
  exoclick_desktop_player2: "",
  exoclick_mobile_player: "",
  exoclick_mobile_player_fullscreen: "",
  exoclick_mobile_footer_ad_2: "",
  auto_tags: "",
  exoclick_mobile_popunder: "",
  exoclick_desktop_popunder: "",
  exoclick_pre_video: "",
  exoclick_outstream_ad: "",
  exoclick_desktop_footer: "",
  exoclick_mobile_footer: "",
  exoclick_list_native: "",
  home_seo_text: "",
  hottest_page_text: "",
  most_liked_page_text: "",
  most_viewed_page_text: "",
  categories_page_text: "",
  pornstars_page_text: "",
  channels_page_text: "",
  exoclick_header_banner: "",
  exoclick_bottom_video_slider: "",
  exoclick_desktop_video_player_bottom_banner: "",
  exoclick_mobile_video_player_bottom_banner: "",
  exoclick_in_page_push: "",
  white_logo: "",
  default_theme: "light",
  auto_star_setting: "mix",
  auto_tag_setting: "mix",
  auto_tag_count: 1,
  passive_categories_for_adding: [],
  other_videos_desktop_limit: 12,
  other_videos_mobile_limit: 6,
  other_videos_require_limit: 10,
  other_videos: 1,
  deleted_content_for_ads: "",
  passive_tags_for_adding: ""
};

class Settings extends React.Component {
  state = {
    activeTab: 0,
    seo: {
      titleLimit: 60,
      descriptionLimit: 230
    },
    settings: defaultSettings,
    loading: false,
    changedLogo: false,
    changedWhiteLogo: false,
    changedFooterLogo: false,
    changedFavicon: false
  };

  changeTabHandle = (event, value) => {
    this.setState({
      activeTab: value
    });
  };

  inputChangeHandler = (name, val) => {
    let settings = {
      ...this.state.settings,
      [name]: val
    };
    this.setState({
      settings: settings
    });
  };

  seoChangeHandler = (type, name, val) => {
    let seo = { ...this.state.seo };
    if (type === "title") {
      if (seo.titleLimit < val.length) {
        return false;
      }
    } else if (type === "description") {
      if (seo.descriptionLimit < val.length) {
        return false;
      }
    }

    this.inputChangeHandler(name, val);

  };

  saveSettings = () => {
    this.setState({
      loading: true
    });
    let postData = { ...this.state.settings };

    postData.domainID = this.props.activeDomain.id;
    postData.changedLogo = this.state.changedLogo;
    postData.changedWhiteLogo = this.state.changedWhiteLogo;
    postData.changedFooterLogo = this.state.changedFooterLogo;
    postData.changedFavicon = this.state.changedFavicon;

    axios.post("setting/save", postData)
      .then(response => {
        this.setState({
          loading: false,
          changedLogo: false,
          changedWhiteLogo: false,
          changedFooterLogo: false,
          changedFavicon: false
        });
        this.props.onUpdateSettings();

      })
      .catch(err => {
        let errors = [];
        Object.keys(err.response.data.errors).map(er => {
          let arr = err.response.data.errors[er];
          arr.map(e => {
            errors.push(e);
            return e;
          });
          return er;
        });

        const error = errors.map(e => {
          return <p key={e}>{e}</p>;
        });
        this.setState({
          loading: false,
          error: error
        });
      });

  };

  uploadInputChangeHandler = e => {

    const formData = new FormData();
    formData.append("photo", e.target.files[0]);
    this.setState({
      loading: true
    });

    axios.post("/setting/upload-logo", formData)
      .then(response => {
        this.setState({
          settings: {
            ...this.state.settings,
            logo: response.data.url
          },
          loading: false,
          changedLogo: true
        });
      });

  };

  uploadWhiteLogoHandler = e => {

    const formData = new FormData();
    formData.append("photo", e.target.files[0]);
    this.setState({
      loading: true
    });

    console.log("white logo upload");
    axios.post("/setting/upload-logo", formData)
      .then(response => {
        console.log("uploaded", response);
        this.setState({
          settings: {
            ...this.state.settings,
            white_logo: response.data.url
          },
          loading: false,
          changedWhiteLogo: true
        });
      });

  };

  uploadFooterLogoHandler = e => {

    const formData = new FormData();
    formData.append("photo", e.target.files[0]);
    this.setState({
      loading: true
    });

    axios.post("/setting/upload-logo", formData)
      .then(response => {
        this.setState({
          settings: {
            ...this.state.settings,
            footer_logo: response.data.url
          },
          loading: false,
          changedFooterLogo: true
        });
      });

  };

  uploadFaviconHandler = e => {

    const formData = new FormData();
    formData.append("photo", e.target.files[0]);
    this.setState({
      loading: true
    });

    axios.post("/setting/upload-logo", formData)
      .then(response => {
        this.setState({
          settings: {
            ...this.state.settings,
            favicon: response.data.url
          },
          loading: false,
          changedFavicon: true
        });
      });

  };


  componentDidMount() {
    this.setState({
      settings: {
        ...this.state.settings,
        ...this.props.settings
      }
    });

  }

  componentDidUpdate(prevProps) {
    if (this.props.activeDomain.id !== null && this.props.activeDomain !== prevProps.activeDomain) {
      this.props.onChangeDomain();
    }

    if (this.props.settings !== prevProps.settings) {
      this.setState({
        settings: {
          ...defaultSettings,
          ...this.props.settings
        }
      });
    }

    return true;
  }

  errorCloseHandler = () => {
    this.setState({
      error: null
    });
  };

  render() {
    let tabContent = null;

    let loading = null;

    if (this.props.loading || this.state.loading || Object.keys(this.state.settings).length < 1) {
      loading = <Loading size={100} />;
    }

    let error = null;
    if (this.state.error) {
      error = (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          open={this.state.error !== null}
          autoHideDuration={5000}
          onClose={this.errorCloseHandler}
          ContentProps={{
            "aria-describedby": "message-id",
            "className": "snackbar danger"
          }}
          message={<span id="message-id">{this.state.error}</span>}
        />
      );
    }

    if (Object.keys(this.state.settings).length > 0) {

      let logo = null;
      if (this.state.settings.logo) {
        logo = (
          <React.Fragment>
            <img src={this.state.settings.logo} alt="Logo" className="SettingsLogo" /> <br />
            <input id="photoUploadInput" accept="image/*" onChange={this.uploadInputChangeHandler}
                   type="file" style={{ position: "absolute", top: "-9999px" }} />
            <label htmlFor="photoUploadInput">
              <Button variant="contained" component="span" color="default" size="small">
                FARKLI LOGO YÜKLE&nbsp;&nbsp;
                <CloudUpload />
              </Button>
            </label>
          </React.Fragment>
        );
      } else {
        logo = (
          <React.Fragment>
            <input id="photoUploadInput" accept="image/*" onChange={this.uploadInputChangeHandler}
                   type="file" style={{ position: "absolute", top: "-9999px" }} />
            <label htmlFor="photoUploadInput">
              <Button variant="contained" component="span" color="default" size="small">
                LOGO YÜKLE&nbsp;&nbsp;
                <CloudUpload />
              </Button>
            </label>
          </React.Fragment>
        );
      }

      let whiteLogo = null;
      if (this.state.settings.white_logo) {
        whiteLogo = (
          <React.Fragment>
            <img style={{ backgroundColor: "#202020" }} src={this.state.settings.white_logo} alt="White Logo"
                 className="SettingsLogo" /> <br />
            <input id="whiteLogoUploadInput" accept="image/*" onChange={this.uploadWhiteLogoHandler}
                   type="file" style={{ position: "absolute", top: "-9999px" }} />
            <label htmlFor="whiteLogoUploadInput">
              <Button variant="contained" component="span" color="default" size="small">
                FARKLI LOGO YÜKLE&nbsp;&nbsp;
                <CloudUpload />
              </Button>
            </label>
          </React.Fragment>
        );
      } else {
        whiteLogo = (
          <React.Fragment>
            <input id="whiteLogoUploadInput" accept="image/*" onChange={this.uploadWhiteLogoHandler}
                   type="file" style={{ position: "absolute", top: "-9999px" }} />
            <label htmlFor="whiteLogoUploadInput">
              <Button variant="contained" component="span" color="default" size="small">
                LOGO YÜKLE&nbsp;&nbsp;
                <CloudUpload />
              </Button>
            </label>
          </React.Fragment>
        );
      }

      let footerLogo = null;
      if (this.state.settings.footer_logo) {
        footerLogo = (
          <React.Fragment>
            <img src={this.state.settings.footer_logo} alt="Footer Logo" className="SettingsLogo" /> <br />
            <input id="footerPhotoUploadInput" accept="image/*" onChange={this.uploadFooterLogoHandler}
                   type="file" style={{ position: "absolute", top: "-9999px" }} />
            <label htmlFor="footerPhotoUploadInput">
              <Button variant="contained" component="span" color="default" size="small">
                FARKLI LOGO YÜKLE&nbsp;&nbsp;
                <CloudUpload />
              </Button>
            </label>
          </React.Fragment>
        );
      } else {
        footerLogo = (
          <React.Fragment>
            <input id="footerPhotoUploadInput" accept="image/*" onChange={this.uploadFooterLogoHandler}
                   type="file" style={{ position: "absolute", top: "-9999px" }} />
            <label htmlFor="footerPhotoUploadInput">
              <Button variant="contained" component="span" color="default" size="small">
                LOGO YÜKLE&nbsp;&nbsp;
                <CloudUpload />
              </Button>
            </label>
          </React.Fragment>
        );
      }
      let favicon = null;
      if (this.state.settings.favicon) {
        favicon = (
          <React.Fragment>
            <img src={this.state.settings.favicon} alt="Favicon" className="SettingsLogo" /> <br />
            <input id="faviconPhotoUploadInput" accept="image/*" onChange={this.uploadFaviconHandler}
                   type="file" style={{ position: "absolute", top: "-9999px" }} />
            <label htmlFor="faviconPhotoUploadInput">
              <Button variant="contained" component="span" color="default" size="small">
                FARKLI FAVICON YÜKLE&nbsp;&nbsp;
                <CloudUpload />
              </Button>
            </label>
          </React.Fragment>
        );
      } else {
        favicon = (
          <React.Fragment>
            <input id="faviconPhotoUploadInput" accept="image/*" onChange={this.uploadFaviconHandler}
                   type="file" style={{ position: "absolute", top: "-9999px" }} />
            <label htmlFor="faviconPhotoUploadInput">
              <Button variant="contained" component="span" color="default" size="small">
                FAVICON YÜKLE&nbsp;&nbsp;
                <CloudUpload />
              </Button>
            </label>
          </React.Fragment>
        );
      }

      switch (this.state.activeTab) {
        case 0: // General
          tabContent = (
            <React.Fragment>
              <Paper square className="GeneralPaper ">
                <h2 className="titleWithDivider"><span>Tema</span></h2>
                <TextField
                  fullWidth
                  select
                  label="Varsayılan Tema"
                  type="text"
                  margin="normal"
                  value={this.state.settings.default_theme}
                  onChange={(e) => this.inputChangeHandler("default_theme", e.target.value)}
                >
                  <MenuItem key={1} value={"dark"}>
                    Dark
                  </MenuItem>
                  <MenuItem key={2} value={"light"}>
                    Light
                  </MenuItem>
                </TextField>
                <h2 className="titleWithDivider"><span>Logo</span></h2>
                {logo}
                <h2 className="titleWithDivider"><span>Dark Tema Logosu</span></h2>
                {whiteLogo}
                <h2 className="titleWithDivider"><span>Footer Logo</span></h2>
                {footerLogo}
                <h2 className="titleWithDivider"><span>Favicon</span></h2>
                {favicon}
                <h2 className="titleWithDivider"><span>Başlık Ayarları</span></h2>
                <TextField
                  fullWidth
                  label="Genel Başlık"
                  value={this.state.settings.general_title}
                  onChange={(e) => this.inputChangeHandler("general_title", e.target.value)}
                  helperText="Sekme başlıklarının sonuna getirilecek genel başlık"
                />
                <h2 className="titleWithDivider"><span>Analytics</span></h2>
                <TextField
                  fullWidth
                  label="Google Analytic ID"
                  value={this.state.settings.google_analytics_id}
                  onChange={(e) => this.inputChangeHandler("google_analytics_id", e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Yandex Metrica ID"
                  margin="normal"
                  value={this.state.settings.yandex_metrica_id}
                  onChange={(e) => this.inputChangeHandler("yandex_metrica_id", e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Alexa Metrics ID"
                  margin="normal"
                  value={this.state.settings.alexa_metrics_id}
                  onChange={(e) => this.inputChangeHandler("alexa_metrics_id", e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Google Search Console Doğrulama Kodu"
                  margin="normal"
                  value={this.state.settings.google_site_verification_code}
                  onChange={(e) => this.inputChangeHandler("google_site_verification_code", e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Yandex Webmaster Doğrulama Kodu"
                  margin="normal"
                  value={this.state.settings.yandex_site_verification_code}
                  onChange={(e) => this.inputChangeHandler("yandex_site_verification_code", e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Exoclick Doğrulama Kodu"
                  margin="normal"
                  value={this.state.settings.exoclick_site_verification_code}
                  onChange={(e) => this.inputChangeHandler("exoclick_site_verification_code", e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Clickadu Doğrulama Kodu"
                  margin="normal"
                  value={this.state.settings.clickadu_site_verification_code}
                  onChange={(e) => this.inputChangeHandler("clickadu_site_verification_code", e.target.value)}
                />


                <h2 className="titleWithDivider"><span>Footer Ayarları</span></h2>
                <TextField
                  fullWidth
                  label="Footer Açıklaması"
                  multiline
                  rows={3}
                  value={this.state.settings.footer_description}
                  margin="normal"
                  onChange={(e) => this.seoChangeHandler("description", "footer_description", e.target.value)}
                />


                <h2 className="titleWithDivider"><span>Önerilen Video Ayarları</span></h2>
                <TextField
                  fullWidth
                  select
                  label="Durum"
                  helperText="Yeterli sayıda video bulunmayan sayfalarda belirlenen sayıda rastgele video gösterilir."
                  type="number"
                  margin="normal"
                  value={this.state.settings.other_videos}
                  onChange={(e) => this.inputChangeHandler("other_videos", e.target.value)}
                >
                  <MenuItem key={1} value={1}>
                    Kapalı
                  </MenuItem>
                  <MenuItem key={2} value={2}>
                    Açık
                  </MenuItem>
                </TextField>

                <TextField
                  fullWidth
                  label="Önerilen Video Gösterim Sınırı"
                  type="number"
                  value={this.state.settings.other_videos_require_limit}
                  onChange={(e) => this.inputChangeHandler("other_videos_require_limit", e.target.value)}
                />

                <TextField
                  fullWidth
                  label="Önerilen Video Sayısı (Masaüstü)"
                  type="number"
                  margin="normal"
                  value={this.state.settings.other_videos_mobile_limit}
                  onChange={(e) => this.inputChangeHandler("other_videos_mobile_limit", e.target.value)}
                />

                <TextField
                  fullWidth
                  label="Önerilen Video Sayısı (Mobil)"
                  type="number"
                  margin="normal"
                  value={this.state.settings.other_videos_desktop_limit}
                  onChange={(e) => this.inputChangeHandler("other_videos_desktop_limit", e.target.value)}
                />


                <Button style={{ marginTop: "15px" }} onClick={this.saveSettings} variant="contained"
                        color="primary">
                  GÜNCELLE
                </Button>
              </Paper>
            </React.Fragment>
          );
          break;
        case 1: // Homepage
          tabContent = (
            <React.Fragment>
              <Paper square className="GeneralPaper">
                <h2 className="titleWithDivider"><span>Anasayfa Ayarları</span></h2>
                <TextField
                  fullWidth
                  label="Seo Başlığı"
                  value={this.state.settings.home_seo_title}
                  onChange={(e) => this.seoChangeHandler("title", "home_seo_title", e.target.value)}
                  helperText={this.state.settings.home_seo_title.length + " / " + this.state.seo.titleLimit}
                />
                <TextField
                  fullWidth
                  label="Seo Açıklaması"
                  multiline
                  rows={3}
                  value={this.state.settings.home_seo_description}
                  margin="normal"
                  onChange={(e) => this.seoChangeHandler("description", "home_seo_description", e.target.value)}
                  helperText={this.state.settings.home_seo_description.length + " / " + this.state.seo.descriptionLimit}
                />
                <TextField
                  fullWidth
                  label="Seo Yazısı"
                  multiline
                  rows={20}
                  value={this.state.settings.home_seo_text}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("home_seo_text", e.target.value)}
                />

                <h2 className="titleWithDivider"><span>Trending Ayarları</span></h2>
                <TextField
                  fullWidth
                  select
                  label="Karışık Sıralama"
                  type="number"
                  margin="normal"
                  value={this.state.settings.trending_random_order}
                  onChange={(e) => this.inputChangeHandler("trending_random_order", e.target.value)}
                >
                  <MenuItem key={1} value={1}>
                    Kapalı
                  </MenuItem>
                  <MenuItem key={2} value={2}>
                    Açık
                  </MenuItem>
                </TextField>

                <TextField
                  fullWidth
                  label="Trending Masaüstü İçerik Sayısı"
                  type="number"
                  value={this.state.settings.home_trending_desktop_list_size}
                  onChange={(e) => this.inputChangeHandler("home_trending_desktop_list_size", e.target.value)}
                />

                <TextField
                  fullWidth
                  label="Trending Mobil İçerik Sayısı"
                  type="number"
                  margin="normal"
                  value={this.state.settings.home_trending_mobile_list_size}
                  onChange={(e) => this.inputChangeHandler("home_trending_mobile_list_size", e.target.value)}
                />


                <h2 className="titleWithDivider"><span>Most Liked Ayarları</span></h2>
                <TextField
                  fullWidth
                  label="Most Liked Masaüstü Listeleme Sayısı"
                  type="number"
                  value={this.state.settings.home_most_liked_desktop_list_size}
                  onChange={(e) => this.inputChangeHandler("home_most_liked_desktop_list_size", e.target.value)}
                />

                <TextField
                  fullWidth
                  label="Most Liked Mobil Listeleme Sayısı"
                  type="number"
                  margin="normal"
                  value={this.state.settings.home_most_liked_mobile_list_size}
                  onChange={(e) => this.inputChangeHandler("home_most_liked_mobile_list_size", e.target.value)}
                />

                <TextField
                  fullWidth
                  label="Most Liked View More Adedi"
                  type="number"
                  margin="normal"
                  value={this.state.settings.home_most_liked_view_more_count}
                  onChange={(e) => this.inputChangeHandler("home_most_liked_view_more_count", e.target.value)}
                />

                <h2 className="titleWithDivider"><span>Hottest Ayarları</span></h2>
                <TextField
                  fullWidth
                  label="Hottest Masaüstü Listeleme Sayısı"
                  type="number"
                  value={this.state.settings.home_hottest_desktop_list_size}
                  onChange={(e) => this.inputChangeHandler("home_hottest_desktop_list_size", e.target.value)}
                />

                <TextField
                  fullWidth
                  label="Hottest Mobil Listeleme Sayısı"
                  type="number"
                  margin="normal"
                  value={this.state.settings.home_hottest_mobile_list_size}
                  onChange={(e) => this.inputChangeHandler("home_hottest_mobile_list_size", e.target.value)}
                />

                <TextField
                  fullWidth
                  label="Hottest View More Adedi"
                  type="number"
                  margin="normal"
                  value={this.state.settings.home_hottest_view_more_count}
                  onChange={(e) => this.inputChangeHandler("home_hottest_view_more_count", e.target.value)}
                />

                <h2 className="titleWithDivider"><span>Pornstar Ayarları</span></h2>
                <TextField
                  fullWidth
                  label="Pornstar Masaüstü İçerik Sayısı"
                  type="number"
                  value={this.state.settings.home_pornstar_desktop_list_size}
                  onChange={(e) => this.inputChangeHandler("home_pornstar_desktop_list_size", e.target.value)}
                />

                <TextField
                  fullWidth
                  label="Pornstar Mobil İçerik Sayısı"
                  type="number"
                  margin="normal"
                  value={this.state.settings.home_pornstar_mobile_list_size}
                  onChange={(e) => this.inputChangeHandler("home_pornstar_mobile_list_size", e.target.value)}
                />
                <h2 className="titleWithDivider"><span>Channel Ayarları</span></h2>
                <TextField
                  fullWidth
                  label="Channel Masaüstü İçerik Sayısı"
                  type="number"
                  value={this.state.settings.home_channel_desktop_list_size}
                  onChange={(e) => this.inputChangeHandler("home_channel_desktop_list_size", e.target.value)}
                />

                <TextField
                  fullWidth
                  label="Channel Mobil İçerik Sayısı"
                  type="number"
                  margin="normal"
                  value={this.state.settings.home_channel_mobile_list_size}
                  onChange={(e) => this.inputChangeHandler("home_channel_mobile_list_size", e.target.value)}
                />
                <Button style={{ marginTop: "15px" }} onClick={this.saveSettings} variant="contained"
                        color="primary">
                  GÜNCELLE
                </Button>
              </Paper>
            </React.Fragment>
          );
          break;
        case 2: // Video Detail
          tabContent = (
            <React.Fragment>
              <Paper square className="GeneralPaper">
                <h2 className="titleWithDivider"><span>Related Videos Ayarları</span></h2>
                <TextField
                  fullWidth
                  label="Masaüstü Sidebar Gösterim Adedi"
                  type="number"
                  margin="normal"
                  value={this.state.settings.detail_primary_list_size}
                  onChange={(e) => this.inputChangeHandler("detail_primary_list_size", e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Masaüstü Video Altı Gösterim Adedi"
                  type="number"
                  margin="normal"
                  value={this.state.settings.detail_secondary_list_size}
                  onChange={(e) => this.inputChangeHandler("detail_secondary_list_size", e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Mobil Gösterim Adedi"
                  type="number"
                  margin="normal"
                  value={this.state.settings.detail_mobile_list_size}
                  onChange={(e) => this.inputChangeHandler("detail_mobile_list_size", e.target.value)}
                />

                <Button style={{ marginTop: "15px" }} onClick={this.saveSettings} variant="contained"
                        color="primary">
                  GÜNCELLE
                </Button>

              </Paper>
            </React.Fragment>
          );
          break;
        case 4: // Categories
          tabContent = (
            <React.Fragment>
              <Paper square className="GeneralPaper ">
                <h2 className="titleWithDivider"><span>Kategoriler Sayfası Ayarları</span></h2>
                <TextField
                  fullWidth
                  label="Başlık"
                  value={this.state.settings.categories_page_title}
                  onChange={(e) => this.seoChangeHandler("title", "categories_page_title", e.target.value)}
                  helperText={this.state.settings.categories_page_title.length + " / " + this.state.seo.titleLimit}
                />
                <TextField
                  fullWidth
                  label="Açıklama"
                  multiline
                  rows={3}
                  value={this.state.settings.categories_page_description}
                  margin="normal"
                  onChange={(e) => this.seoChangeHandler("description", "categories_page_description", e.target.value)}
                  helperText={this.state.settings.categories_page_description.length + " / " + this.state.seo.descriptionLimit}
                />
                <TextField
                  fullWidth
                  label="Seo Yazısı"
                  multiline
                  rows={20}
                  value={this.state.settings.categories_page_text}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("categories_page_text", e.target.value)}
                />

                <h2 className="titleWithDivider"><span>Kategori Detay Sayfası Ayarları</span></h2>
                <TextField
                  fullWidth
                  label="Masaüstü Listeleme Sayısı"

                  type="number"
                  value={this.state.settings.categories_videos_desktop_list_size}
                  onChange={(e) => this.inputChangeHandler("categories_videos_desktop_list_size", e.target.value)}
                />

                <TextField
                  fullWidth
                  label="Mobil Listeleme Sayısı"
                  type="number"
                  margin="normal"
                  value={this.state.settings.categories_videos_mobile_list_size}
                  onChange={(e) => this.inputChangeHandler("categories_videos_mobile_list_size", e.target.value)}
                />

                <TextField
                  fullWidth
                  label="Seo Description Şablonu"
                  multiline
                  rows={2}
                  value={this.state.settings.category_seo_description_template}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("category_seo_description_template", e.target.value)}
                  helperText={"Kullanılabilecek Değişkenler: {category}"}
                />


                <Button style={{ marginTop: "15px" }} onClick={this.saveSettings} variant="contained"
                        color="primary">
                  GÜNCELLE
                </Button>
              </Paper>
            </React.Fragment>
          );
          break;
        case 5: //Pornstars
          tabContent = (
            <React.Fragment>
              <Paper square className="GeneralPaper ">
                <h2 className="titleWithDivider"><span>Yıldızlar Sayfası Ayarları</span></h2>
                <TextField
                  fullWidth
                  label="Başlık"
                  value={this.state.settings.pornstars_page_title}
                  onChange={(e) => this.seoChangeHandler("title", "pornstars_page_title", e.target.value)}
                  helperText={this.state.settings.pornstars_page_title.length + " / " + this.state.seo.titleLimit}
                />
                <TextField
                  fullWidth
                  label="Açıklama"
                  multiline
                  rows={3}
                  value={this.state.settings.pornstars_page_description}
                  margin="normal"
                  onChange={(e) => this.seoChangeHandler("description", "pornstars_page_description", e.target.value)}
                  helperText={this.state.settings.pornstars_page_description.length + " / " + this.state.seo.descriptionLimit}
                />
                <TextField
                  fullWidth
                  label="Seo Yazısı"
                  multiline
                  rows={20}
                  value={this.state.settings.pornstars_page_text}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("pornstars_page_text", e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Masaüstü Listeleme Sayısı"
                  margin="normal"
                  type="number"
                  value={this.state.settings.pornstars_desktop_list_size}
                  onChange={(e) => this.inputChangeHandler("pornstars_desktop_list_size", e.target.value)}
                />

                <TextField
                  fullWidth
                  label="Mobil Listeleme Sayısı"
                  type="number"
                  margin="normal"
                  value={this.state.settings.pornstars_mobile_list_size}
                  onChange={(e) => this.inputChangeHandler("pornstars_mobile_list_size", e.target.value)}
                />

                <h2 className="titleWithDivider"><span>Yıldız Detay Sayfası Ayarları</span></h2>
                <TextField
                  fullWidth
                  label="Masaüstü Listeleme Sayısı"

                  type="number"
                  value={this.state.settings.pornstars_videos_desktop_list_size}
                  onChange={(e) => this.inputChangeHandler("pornstars_videos_desktop_list_size", e.target.value)}
                />

                <TextField
                  fullWidth
                  label="Mobil Listeleme Sayısı"
                  type="number"
                  margin="normal"
                  value={this.state.settings.pornstars_videos_mobile_list_size}
                  onChange={(e) => this.inputChangeHandler("pornstars_videos_mobile_list_size", e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Seo Description Şablonu"
                  multiline
                  rows={2}
                  value={this.state.settings.pornstar_seo_description_template}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("pornstar_seo_description_template", e.target.value)}
                  helperText={"Kullanılabilecek Değişkenler: {pornstar}"}
                />

                <Button style={{ marginTop: "15px" }} onClick={this.saveSettings} variant="contained"
                        color="primary">
                  GÜNCELLE
                </Button>
              </Paper>
            </React.Fragment>
          );
          break;
        case 6: // Channels
          tabContent = (
            <React.Fragment>
              <Paper square className="GeneralPaper ">
                <h2 className="titleWithDivider"><span>Kanallar Sayfası Ayarları</span></h2>
                <TextField
                  fullWidth
                  label="Başlık"
                  value={this.state.settings.channels_page_title}
                  onChange={(e) => this.seoChangeHandler("title", "channels_page_title", e.target.value)}
                  helperText={this.state.settings.channels_page_title.length + " / " + this.state.seo.titleLimit}
                />
                <TextField
                  fullWidth
                  label="Açıklama"
                  multiline
                  rows={3}
                  value={this.state.settings.channels_page_description}
                  margin="normal"
                  onChange={(e) => this.seoChangeHandler("description", "channels_page_description", e.target.value)}
                  helperText={this.state.settings.channels_page_description.length + " / " + this.state.seo.descriptionLimit}
                />
                <TextField
                  fullWidth
                  label="Seo Yazısı"
                  multiline
                  rows={20}
                  value={this.state.settings.channels_page_text}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("channels_page_text", e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Masaüstü Listeleme Sayısı"
                  margin="normal"
                  type="number"
                  value={this.state.settings.channels_desktop_list_size}
                  onChange={(e) => this.inputChangeHandler("channels_desktop_list_size", e.target.value)}
                />

                <TextField
                  fullWidth
                  label="Mobil Listeleme Sayısı"
                  type="number"
                  margin="normal"
                  value={this.state.settings.channels_mobile_list_size}
                  onChange={(e) => this.inputChangeHandler("channels_mobile_list_size", e.target.value)}
                />

                <h2 className="titleWithDivider"><span>Kanal Detay Sayfası Ayarları</span></h2>
                <TextField
                  fullWidth
                  label="Masaüstü Listeleme Sayısı"

                  type="number"
                  value={this.state.settings.channels_videos_desktop_list_size}
                  onChange={(e) => this.inputChangeHandler("channels_videos_desktop_list_size", e.target.value)}
                />

                <TextField
                  fullWidth
                  label="Mobil Listeleme Sayısı"
                  type="number"
                  margin="normal"
                  value={this.state.settings.channels_videos_mobile_list_size}
                  onChange={(e) => this.inputChangeHandler("channels_videos_mobile_list_size", e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Seo Description Şablonu"
                  multiline
                  rows={2}
                  value={this.state.settings.channel_seo_description_template}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("channel_seo_description_template", e.target.value)}
                  helperText={"Kullanılabilecek Değişkenler: {channel}"}
                />

                <Button style={{ marginTop: "15px" }} onClick={this.saveSettings} variant="contained"
                        color="primary">
                  GÜNCELLE
                </Button>
              </Paper>
            </React.Fragment>
          );
          break;
        case 9: // Social Media

          tabContent = (
            <React.Fragment>
              <Paper square className="GeneralPaper ">

                <TextField
                  fullWidth
                  label="Twitter"
                  value={this.state.settings.social_media_twitter}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("social_media_twitter", e.target.value)}
                />

                <TextField
                  fullWidth
                  label="VK"
                  value={this.state.settings.social_media_vk}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("social_media_vk", e.target.value)}
                />

                <TextField
                  fullWidth
                  label="Reddit"
                  value={this.state.settings.social_media_reddit}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("social_media_reddit", e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Facebook"
                  value={this.state.settings.social_media_facebook}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("social_media_facebook", e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Instagram"
                  value={this.state.settings.social_media_instagram}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("social_media_instagram", e.target.value)}
                />

                <Button style={{ marginTop: "15px" }} onClick={this.saveSettings} variant="contained"
                        color="primary">
                  GÜNCELLE
                </Button>
              </Paper>
            </React.Fragment>
          );
          break;
        case 10: // Advertisement

          tabContent = (
            <React.Fragment>
              <Paper square className="GeneralPaper ">
                <TextField
                  fullWidth
                  label="Exoclick Video Önü"
                  value={this.state.settings.exoclick_pre_video}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("exoclick_pre_video", e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Exoclick Masaüstü Video Detay Sidebar"
                  value={this.state.settings.exoclick_desktop_video_detail_sidebar}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("exoclick_desktop_video_detail_sidebar", e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Exoclick Masaüstü Video Detay Video Altı"
                  value={this.state.settings.exoclick_desktop_video_detail_under_video}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("exoclick_desktop_video_detail_under_video", e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Exoclick Mobile Video Detay Video Altı"
                  value={this.state.settings.exoclick_mobile_video_detail_under_video}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("exoclick_mobile_video_detail_under_video", e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Exoclick Masaüstü Footer 1"
                  value={this.state.settings.exoclick_desktop_footer_ad_1}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("exoclick_desktop_footer_ad_1", e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Exoclick Masaüstü Footer 2"
                  value={this.state.settings.exoclick_desktop_footer_ad_2}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("exoclick_desktop_footer_ad_2", e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Exoclick Masaüstü Footer 3"
                  value={this.state.settings.exoclick_desktop_footer_ad_3}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("exoclick_desktop_footer_ad_3", e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Exoclick Masaüstü Footer 4"
                  value={this.state.settings.exoclick_desktop_footer_ad_4}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("exoclick_desktop_footer_ad_4", e.target.value)}
                />

                <TextField
                  fullWidth
                  label="Exoclick Mobil Footer"
                  value={this.state.settings.exoclick_mobile_footer_ad}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("exoclick_mobile_footer_ad", e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Exoclick Mobil Footer 2"
                  value={this.state.settings.exoclick_mobile_footer_ad_2}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("exoclick_mobile_footer_ad_2", e.target.value)}
                />

                <TextField
                  fullWidth
                  label="Exoclick Masaüstü Player 1"
                  value={this.state.settings.exoclick_desktop_player1}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("exoclick_desktop_player1", e.target.value)}
                />

                <TextField
                  fullWidth
                  label="Exoclick Masaüstü Player 2"
                  value={this.state.settings.exoclick_desktop_player2}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("exoclick_desktop_player2", e.target.value)}
                />

                <TextField
                  fullWidth
                  label="Exoclick Mobil Player"
                  value={this.state.settings.exoclick_mobile_player}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("exoclick_mobile_player", e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Exoclick Mobil Player (Fullscreen)"
                  value={this.state.settings.exoclick_mobile_player_fullscreen}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("exoclick_mobile_player_fullscreen", e.target.value)}
                />

                <TextField
                  fullWidth
                  label="Exoclick Masaüstü PopUnder"
                  value={this.state.settings.exoclick_desktop_popunder}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("exoclick_desktop_popunder", e.target.value)}
                />

                <TextField
                  fullWidth
                  label="Exoclick Mobile PopUnder"
                  value={this.state.settings.exoclick_mobile_popunder}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("exoclick_mobile_popunder", e.target.value)}
                />

                <TextField
                  fullWidth
                  label="Exoclick Header Banner"
                  value={this.state.settings.exoclick_header_banner}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("exoclick_header_banner", e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Exoclick Sağ Alt Video Slider"
                  value={this.state.settings.exoclick_bottom_video_slider}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("exoclick_bottom_video_slider", e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Exoclick Video Player Altı Banner (Masaüstü)"
                  value={this.state.settings.exoclick_desktop_video_player_bottom_banner}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("exoclick_desktop_video_player_bottom_banner", e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Exoclick Video Player Altı Banner (Mobil)"
                  value={this.state.settings.exoclick_mobile_video_player_bottom_banner}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("exoclick_mobile_video_player_bottom_banner", e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Exoclick Outstream"
                  value={this.state.settings.exoclick_outstream_ad}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("exoclick_outstream_ad", e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Exoclick Masaüstü Footer"
                  value={this.state.settings.exoclick_desktop_footer}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("exoclick_desktop_footer", e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Exoclick Mobil Footer"
                  value={this.state.settings.exoclick_mobile_footer}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("exoclick_mobile_footer", e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Exoclick Listeleme Native"
                  value={this.state.settings.exoclick_list_native}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("exoclick_list_native", e.target.value)}
                />

                <TextField
                  fullWidth
                  label="Exoclick Sayfa İçi Push Notification"
                  value={this.state.settings.exoclick_in_page_push}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("exoclick_in_page_push", e.target.value)}
                />

                <Button style={{ marginTop: "15px" }} onClick={this.saveSettings} variant="contained"
                        color="primary">
                  GÜNCELLE
                </Button>
              </Paper>
            </React.Fragment>
          );
          break;
        case 7: // Tag

          tabContent = (
            <React.Fragment>
              <Paper square className="GeneralPaper ">

                <h2 className="titleWithDivider"><span>Etiket Sayfası Ayarları</span></h2>

                <TextField
                  fullWidth
                  label="Masaüstü Listeleme Sayısı"
                  type="number"
                  margin="normal"
                  value={this.state.settings.tag_desktop_list_size}
                  onChange={(e) => this.inputChangeHandler("tag_desktop_list_size", e.target.value)}
                />

                <TextField
                  fullWidth
                  label="Mobil Listeleme Sayısı"
                  type="number"
                  margin="normal"
                  value={this.state.settings.tag_mobile_list_size}
                  onChange={(e) => this.inputChangeHandler("tag_mobile_list_size", e.target.value)}
                />

                <TextField
                  fullWidth
                  label="Seo Description Şablonu"
                  multiline
                  rows={2}
                  value={this.state.settings.tag_seo_description_template}
                  margin="normal"
                  onChange={(e) => this.inputChangeHandler("tag_seo_description_template", e.target.value)}
                  helperText={"Kullanılabilecek Değişkenler: {tag}"}
                />

                <Button style={{ marginTop: "15px" }} onClick={this.saveSettings} variant="contained"
                        color="primary">
                  GÜNCELLE
                </Button>
              </Paper>
            </React.Fragment>
          );
          break;
        case 8: // Other Pages
          tabContent = <React.Fragment>
            <Paper square className="GeneralPaper ">
              <h2 className="titleWithDivider"><span>Hottest Sayfa Ayarları</span></h2>
              <TextField
                fullWidth
                label="Sayfa Başlığı"
                value={this.state.settings.hottest_page_title}
                onChange={(e) => this.inputChangeHandler("hottest_page_title", e.target.value)}
                helperText={this.state.settings.hottest_page_title.length + " / " + this.state.seo.titleLimit}
              />
              <TextField
                fullWidth
                label="Sayfa Açıklaması"
                margin="normal"
                value={this.state.settings.hottest_page_description}
                onChange={(e) => this.inputChangeHandler("hottest_page_description", e.target.value)}
                helperText={this.state.settings.hottest_page_description.length + " / " + this.state.seo.titleLimit}
              />
              <TextField
                fullWidth
                label="Seo Yazısı"
                multiline
                rows={20}
                value={this.state.settings.hottest_page_text}
                margin="normal"
                onChange={(e) => this.inputChangeHandler("hottest_page_text", e.target.value)}
              />
              <TextField
                fullWidth
                label="Hottest Masaüstü Listeleme Sayısı"
                type="number"
                margin="normal"
                value={this.state.settings.hottest_desktop_list_size}
                onChange={(e) => this.inputChangeHandler("hottest_desktop_list_size", e.target.value)}
              />

              <TextField
                fullWidth
                label="Hottest Mobil Listeleme Sayısı"
                type="number"
                margin="normal"
                value={this.state.settings.hottest_mobile_list_size}
                onChange={(e) => this.inputChangeHandler("hottest_mobile_list_size", e.target.value)}
              />

              <h2 className="titleWithDivider"><span>Most Liked Sayfa Ayarları</span></h2>
              <TextField
                fullWidth
                label="Sayfa Başlığı"
                value={this.state.settings.most_liked_page_title}
                onChange={(e) => this.inputChangeHandler("most_liked_page_title", e.target.value)}
                helperText={this.state.settings.most_liked_page_title.length + " / " + this.state.seo.titleLimit}
              />
              <TextField
                fullWidth
                label="Sayfa Açıklaması"
                margin="normal"
                value={this.state.settings.most_liked_page_description}
                onChange={(e) => this.inputChangeHandler("most_liked_page_description", e.target.value)}
                helperText={this.state.settings.most_liked_page_description.length + " / " + this.state.seo.titleLimit}
              />

              <TextField
                fullWidth
                label="Seo Yazısı"
                multiline
                rows={20}
                value={this.state.settings.most_liked_page_text}
                margin="normal"
                onChange={(e) => this.inputChangeHandler("most_liked_page_text", e.target.value)}
              />
              <TextField
                fullWidth
                label="Most Liked Masaüstü Listeleme Sayısı"
                type="number"
                margin="normal"
                value={this.state.settings.most_liked_desktop_list_size}
                onChange={(e) => this.inputChangeHandler("most_liked_desktop_list_size", e.target.value)}
              />

              <TextField
                fullWidth
                label="Most Liked Mobil Listeleme Sayısı"
                type="number"
                margin="normal"
                value={this.state.settings.most_liked_mobile_list_size}
                onChange={(e) => this.inputChangeHandler("most_liked_mobile_list_size", e.target.value)}
              />

              <h2 className="titleWithDivider"><span>Most Viewed Sayfa Ayarları</span></h2>
              <TextField
                fullWidth
                label="Sayfa Başlığı"
                value={this.state.settings.most_viewed_page_title}
                onChange={(e) => this.inputChangeHandler("most_viewed_page_title", e.target.value)}
                helperText={this.state.settings.most_viewed_page_title.length + " / " + this.state.seo.titleLimit}
              />
              <TextField
                fullWidth
                label="Sayfa Açıklaması"
                margin="normal"
                value={this.state.settings.most_viewed_page_description}
                onChange={(e) => this.inputChangeHandler("most_viewed_page_description", e.target.value)}
                helperText={this.state.settings.most_viewed_page_description.length + " / " + this.state.seo.titleLimit}
              />
              <TextField
                fullWidth
                label="Seo Yazısı"
                multiline
                rows={20}
                value={this.state.settings.most_viewed_page_text}
                margin="normal"
                onChange={(e) => this.inputChangeHandler("most_viewed_page_text", e.target.value)}
              />
              <TextField
                fullWidth
                label="Most Viewed Masaüstü Listeleme Sayısı"
                type="number"
                margin="normal"
                value={this.state.settings.most_viewed_desktop_list_size}
                onChange={(e) => this.inputChangeHandler("most_viewed_desktop_list_size", e.target.value)}
              />

              <TextField
                fullWidth
                label="Most Viewed Mobil Listeleme Sayısı"
                type="number"
                margin="normal"
                value={this.state.settings.most_viewed_mobile_list_size}
                onChange={(e) => this.inputChangeHandler("most_viewed_mobile_list_size", e.target.value)}
              />


              <h2 className="titleWithDivider"><span>Arama Sayfası Ayarları</span></h2>

              <TextField
                fullWidth
                label="Masaüstü Listeleme Sayısı"
                type="number"
                margin="normal"
                value={this.state.settings.search_desktop_list_size}
                onChange={(e) => this.inputChangeHandler("search_desktop_list_size", e.target.value)}
              />

              <TextField
                fullWidth
                label="Mobil Listeleme Sayısı"
                type="number"
                margin="normal"
                value={this.state.settings.search_mobile_list_size}
                onChange={(e) => this.inputChangeHandler("search_mobile_list_size", e.target.value)}
              />
              <Button style={{ marginTop: "15px" }} onClick={this.saveSettings} variant="contained"
                      color="primary">
                GÜNCELLE
              </Button>
            </Paper>
          </React.Fragment>;
          break;
        case 3: // Add Content Settings
          tabContent = <React.Fragment>
            <Paper square className="GeneralPaper ">
              <TextField
                fullWidth
                label="Seo Description Şablonu"
                multiline
                rows={8}
                value={this.state.settings.detail_seo_description_template}
                margin="normal"
                onChange={(e) => this.inputChangeHandler("detail_seo_description_template", e.target.value)}
                helperText={"Kullanılabilecek Değişkenler: {title}, {seoTitle}, {duration}, {categories:kategoriSayisi}, {channels}, {pornstars}. Her satıra bir açıklama girilebilir."}
              />
              <h2 className="titleWithDivider"><span>Otomatik Tag Ekleme Sistemi</span></h2>
              <TextField
                fullWidth
                label="Otomatik Tag"
                multiline
                rows={10}
                value={this.state.settings.auto_tags}
                margin="normal"
                onChange={(e) => this.inputChangeHandler("auto_tags", e.target.value)}
                helperText="Eklenen taglerden otomatik olarak rastgele seçilen bir tanesi yeni videoya eklenir. Her satıra bir tag giriniz."
              />

              <TextField
                fullWidth
                select
                label="Otomatik Tag Ayarı"
                type="number"
                margin="normal"
                value={this.state.settings.auto_tag_setting}
                onChange={(e) => this.inputChangeHandler("auto_tag_setting", e.target.value)}
                helperText="Yeni video eklenirken otomatik eklenecek taglerin seçimi"
              >
                <MenuItem key={"mix"} value={"mix"}>
                  Video Tagleri + Otomatik Tagler
                </MenuItem>
                <MenuItem key={"onlyVideoTags"} value={"onlyVideoTags"}>
                  Sadece Video Tagleri
                </MenuItem>
                <MenuItem key={"onlyAutoTags"} value={"onlyAutoTags"}>
                  Sadece Otomatik Tagler
                </MenuItem>
                <MenuItem key={"disable"} value={"disable"}>
                  Tag Getirme
                </MenuItem>
              </TextField>
              <TextField
                fullWidth
                label="Otomatik Tag Ekleme Sayısı"
                type="number"
                margin="normal"
                value={this.state.settings.auto_tag_count}
                onChange={(e) => this.inputChangeHandler("auto_tag_count", e.target.value)}
                helperText="Yeni video eklenirken otomatik tagler arasından kaç tanesi eklenecek?"
              />
              <h2 className="titleWithDivider"><span>Kategori Ayarları</span></h2>
              <Select
                changed={(val) => this.inputChangeHandler("passive_categories_for_adding", val)}
                placeholder="Kategoriler"
                // options={this.state.categories}
                value={this.state.settings.passive_categories_for_adding}
                label="Ekleme İçin Pasif Kategoriler"
                multi
                ajaxUrl="category/searchCategory"
                domainID={this.props.activeDomain.id}
                className="AddVideoSelect"
                helperText={"Belirleyeceğiniz kategoriler video eklerken videoda bulunsa bile seçili getirilmeyecektir."}
              />

              <h2 className="titleWithDivider"><span>Pornstar Ayarları</span></h2>
              <TextField
                fullWidth
                select
                label="Otomatik Eklenecek Pornstar Seçenekleri"
                type="number"
                margin="normal"
                value={this.state.settings.auto_star_setting}
                onChange={(e) => this.inputChangeHandler("auto_star_setting", e.target.value)}
                helperText="Yeni video eklenirken otomatik eklenecek pornstarların seçimi"
              >
                <MenuItem key={"mix"} value={"mix"}>
                  Pornstarlar + Modeller
                </MenuItem>
                <MenuItem key={"onlyPornstar"} value={"onlyPornstar"}>
                  Sadece Pornstarlar
                </MenuItem>
                <MenuItem key={"onlyModel"} value={"onlyModel"}>
                  Sadece Modeller
                </MenuItem>
                <MenuItem key={"disable"} value={"disable"}>
                  Pornstar ya da Model Getirme
                </MenuItem>
              </TextField>

              <h2 className="titleWithDivider"><span>Reklam Ayarları</span></h2>
              <TextField
                fullWidth
                label="Reklam İçin Silinen Videolar"
                multiline
                rows={20}
                value={this.state.settings.deleted_content_for_ads}
                margin="normal"
                onChange={(e) => this.inputChangeHandler("deleted_content_for_ads", e.target.value)}
              />

              <h2 className="titleWithDivider"><span>Tag Ayarları</span></h2>
              <TextField
                fullWidth
                label="Ekleme İçin Pasif Tagler"
                multiline
                rows={20}
                value={this.state.settings.passive_tags_for_adding}
                margin="normal"
                onChange={(e) => this.inputChangeHandler("passive_tags_for_adding", e.target.value)}
                helperText="Belirleyeceğiniz etiketler video eklerken videoda bulunsa bile seçili getirilmeyecektir."
              />
              <Button style={{ marginTop: "15px" }} onClick={this.saveSettings} variant="contained"
                      color="primary">
                GÜNCELLE
              </Button>
            </Paper>
          </React.Fragment>;
          break;
        default:
          tabContent = null;
          break;
      }


    }


    return (
      <Grid container spacing={24}>

        {loading}
        {error}
        <Grid item xs={12} sm={12} lg={12}>
          <AppBar position="static" color="default">
            <Tabs
              value={this.state.activeTab}
              onChange={this.changeTabHandle}
              // fullWidth
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="secondary"
            >

              <Tab icon={<SettingsIcon />} label="Genel" />
              <Tab icon={<Home />} label="ANASAYFA" />
              <Tab icon={<VideoLabel />} label="VİDEO DETAY" />
              <Tab icon={<NoteAdd />} label="İÇERİK EKLEME AYARLARI" />
              <Tab icon={<ViewModule />} label="KATEGORİLER" />
              <Tab icon={<Star />} label="YILDIZLAR" />
              <Tab icon={<LiveTv />} label="KANALLAR" />
              <Tab icon={<LocalOffer />} label="ETİKET" />
              <Tab icon={<Description />} label="DİĞER SAYFALAR" />
              <Tab icon={<Share />} label="SOSYAL MEDYA" />
              <Tab icon={<SentimentVerySatisfied />} label="REKLAM" />
            </Tabs>
          </AppBar>

          {tabContent}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    settings: state.setting.settings,
    activeDomain: state.domain.activeDomain,
    loading: state.setting.loading
  };
};

const mapStateToDispatch = dispatch => {
  return {
    onChangeDomain: () => dispatch(actions.saveSettings()),
    onUpdateSettings: () => dispatch(actions.saveSettings())
  };
};

export default connect(mapStateToProps, mapStateToDispatch)(Settings); 